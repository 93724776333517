<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <img
          id="logo-header"
          :src="require('@/assets/images/logo-header.png')"
        />
        <ion-buttons slot="primary">
          <ion-button id="button-menu">
            <ion-icon slot="icon-only" :icon="personCircle"></ion-icon>
            <ion-popover trigger="button-menu">
              <ion-content>
                <ion-list>
                  <ion-item :button="true" :detail="false" @click="logout()">
                    <ion-label>Se déconnecter</ion-label>
                  </ion-item>
                </ion-list>                
              </ion-content>
            </ion-popover>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
      <ion-toolbar>
        <ion-searchbar
          v-model="search"
          debounce="300"
          @ionChange="getVideos()"
          placeholder="Rechercher des vidéos"
        ></ion-searchbar>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <div v-if="isLoading === true" class="loading">
        <ion-spinner name="circles"></ion-spinner>
      </div>

      <div v-if="isLoading === false" class="video-grid">
        <div
          v-for="video in videos"
          :key="video.id"
          v-on:click="openVideo(video)"
          class="video-container"
        >
          <ion-card>
            <video height="200" preload="metadata">
              <source :src="`${video.url}#t=2`" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <ion-card-header>
              <ion-card-subtitle
                >Ajoutée le :
                {{
                  new Date(video.uploadedAt).toLocaleDateString()
                }}</ion-card-subtitle
              >
              <ion-card-title>{{ video.name }}</ion-card-title>
            </ion-card-header>
          </ion-card>
        </div>
      </div>
    </ion-content>

    <ion-modal
      :is-open="videoOpened"
      class="fullscreen"
      @didDismiss="closeVideo()"
    >
      <ion-content v-if="selectedVideo !== null">
        <div id="modal-buttons">
          <ion-button color="primary" v-on:click="copyLink">
            Copier le lien
          </ion-button>
          <ion-button color="light" v-on:click="closeVideo"> Fermer </ion-button>
        </div>

        <input
          id="video-url"
          type="text"
          style="display: none"
          v-model="videoUrl"
        />

        <video id="video-fullscreen" controls autoplay>
          <source :src="selectedVideo.url" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </ion-content>
    </ion-modal>    
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonButtons,
  IonButton,
  IonPopover,
  IonIcon,
  IonList,
  IonItem,
  IonLabel,
  IonSearchbar,
  IonSpinner,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonModal,
  useIonRouter
} from "@ionic/vue";
import { personCircle } from "ionicons/icons";
import { defineComponent } from "vue";
import storage from "@/services/storage.service";

import http from "@/services/http.service";

export default defineComponent({
  name: "Home",
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonButtons,
    IonButton,
    IonPopover,
    IonIcon,
    IonList,
    IonItem,
    IonLabel,
    IonSearchbar,
    IonSpinner,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonModal,
  },
  setup() {
    return { personCircle: personCircle, router: useIonRouter() };
  },
  created() {
    // Get vides
    this.getVideos();
  },
  mounted() {},
  data() {
    return {
      isLoading: true,
      search: null,
      videos: null,
      selectedVideo: null,
      videoOpened: false,
      videoUrl: null,
    };
  },
  methods: {
    getVideos() {
      this.isLoading = true;

      http
        .getVideos(this.search)
        .then((response) => {
          this.videos = response.data;

          // Load video if ?video=
          let urlParams = new URLSearchParams(window.location.search);
          if (urlParams.has("video") && urlParams.get("video") !== null) {
            let video = this.videos.find(
              (v) => v.name === urlParams.get("video")
            );
            if (video) {
              this.openVideo(video);
            }
          }
        })
        .catch(() => {
          this.openToast("Une erreur est survenue", "danger");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    openVideo(video) {
      this.selectedVideo = video;
      this.videoOpened = true;

      // Update route params
      if (history.pushState) {
        let searchParams = new URLSearchParams(window.location.search);
        searchParams.set("video", this.selectedVideo.name);
        let newurl =
          window.location.protocol +
          "//" +
          window.location.host +
          window.location.pathname +
          "?" +
          searchParams.toString();
        window.history.pushState({ path: newurl }, "", newurl);
        this.videoUrl = newurl;
      }
    },
    closeVideo() {
      this.selectedVideo = null;
      this.videoOpened = false;

      // Update route params
      if (history.pushState) {
        let searchParams = new URLSearchParams(window.location.search);
        searchParams.delete("video");
        let newurl =
          window.location.protocol +
          "//" +
          window.location.host +
          window.location.pathname +
          "?" +
          searchParams.toString();
        window.history.pushState({ path: newurl }, "", newurl);
        this.videoUrl = null;
      }
    },
    copyLink() {
      /* Get the text field */
      const element = document.getElementById("video-url");

      /* Select the text field */
      element.select();
      element.setSelectionRange(0, 99999); /* For mobile devices */

      /* Copy the text inside the text field */
      navigator.clipboard.writeText(element.value);

      /* Alert the copied text */
      this.openToast("Lien de la vidéo copiée", "success");
    },
    logout() {
      this.$store.dispatch("global/setAuth", false);
      this.$store.dispatch("global/unsetAuthCredentials");

      storage.removeItem("user");

      this.router.push("/login");
    }
  },
});
</script>

<style lang="scss" scoped>
#logo-header {
  display: inline-block;
  width: 200px;
  margin: 0 auto;
}

div.loading {
  display: flex;
  justify-content: center;
  padding: 24px;
}

div.video-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  & > div.video-container {
    flex: 1;
    max-width: 400px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
      position: relative;
      z-index: 111;
      transform: scale(1.05);
    }

    & ion-card {
      height: 320px;      
    }

    & video {
      width: 100%;
      object-fit: fill;
    }
  }
}

ion-modal.fullscreen {
  --width: 90%;
  --height: 90%;
  --border-radius: 0;
  text-align: center;

  & ion-content:hover {
    & #modal-buttons {
      opacity: 1;
    }
  }

  & #modal-buttons {
    position: absolute;
    right: 0;
    opacity: 0;
    transition: all 0.5s ease-in-out;
    z-index: 110;
  }

  & video {
    margin: 0 auto;
    height: 100%;
    object-fit: contain;
  }
}
</style>
